import React from "react";
import "./login.scss";
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import FormPasswordField from "../../components/formFields/FormPasswordField";
import {
  passwordValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import { useNavigate } from "react-router-dom";
import { employeeLogin } from "./loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormSelectionField from "../../components/formFields/FormSelectionField";

const NewLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector((state) => state.login.loading);

  const handleSubmit = async (values: any) => {
    const data = {
      isemployee: values?.isemployee === 1 ? true : false,
      user_name: values?.UserName,
      password: values?.Password,
    };
    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/");
    }
  };

  return (
    <div className="container-fluid login-page">
      <div className="container login-container">
        <h1>Log In</h1>
        <div className="login-image-box">
          <div className="login-box">
            {/* <h2>Login</h2> */}
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                isemployee: null,
                UserName: "",
                Password: "",
                RememberMe: false,
              }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <Field
                    labelStyle={{
                      fontWeight: "bold",
                      textShadow: "2px 2px 11px white",
                    }}
                    wrapperClassName="pt-3"
                    name={"isemployee"}
                    label={"User Type"}
                    validator={requiredValidator}
                    astrike={true}
                    component={FormSelectionField}
                    options={[
                      { value: 1, label: "Employee" },
                      { value: 2, label: "Vendor" },
                    ]}
                  />
                  <Field
                    labelStyle={{
                      fontWeight: "bold",
                      textShadow: "2px 2px 11px white",
                    }}
                    name={"UserName"}
                    type={"text"}
                    component={FormTextField}
                    label={"User Name"}
                    validator={requiredValidator}
                    astrike={true}
                  />
                  <Field
                    labelStyle={{
                      fontWeight: "bold",
                      textShadow: "2px 2px 11px white",
                    }}
                    name={"Password"}
                    type={"password"}
                    component={FormPasswordField}
                    label={"Password"}
                    validator={passwordValidator}
                  />
                  <ButtonWithLoading
                    label="Login"
                    buttonStyle={{ marginTop: 20 }}
                    themeColor="primary"
                    type="submit"
                    width="100%"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                  {/* <div className="register">
                    <p>
                      Not registered yet? <br />
                      <Link to="/signup">Register Now</Link>
                    </p>
                  </div> */}
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLogin;
