import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import { clearStockReportList } from "../reportsSlice";
import { IItem } from "../../Item/itemModel";
import {
  Grid,
  GridColumn,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import { getItemStockReport } from "../services/reports.services";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import {
  requiredValidator,
  skipValidator,
} from "../../../components/formFields/CommonValidator";
import { getGRNByItemID } from "../../inwardreturn/services/inwardReturn.services";
import FormIncrementalSearch from "../../../components/formFields/FormIncrementalSearch";
import { getAllItemIncremental } from "../../Item/services/item.services";
import { clearItemList } from "../../Item/itemSlice";
import { Button } from "@progress/kendo-react-buttons";
import Logo from "../../../assets/Images/logo.png";
import {
  checkAcessRights,
  formatIndianNumberForQty,
} from "../../../_helper/helper";
import { PRINT_ACCESS } from "../../../_contstants/common";
import { IProcess } from "../../process/processModel";
import { findAllActiveProcess } from "../../process/services/process.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const ItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const item_id = formRenderProps.valueGetter("item_id");
  const process_id = formRenderProps.valueGetter("process_id");

  useEffect(() => {
    const fetchGRNOptions = async () => {
      formRenderProps.onChange("grn_no", {
        value: null,
      });
      if (item_id && process_id) {
        const grnPayload = {
          item_id: item_id,
          process_id: +process_id,
        };
        const response: any = await dispatch(getGRNByItemID(grnPayload));
        if (response && response.payload) {
          const result = response.payload
            .filter((grn: any) => grn?.item_id === item_id)
            .map((grnno: any) => ({
              value: `${grnno?.grn_no}`,
              label: `${grnno?.grn_no}`,
            }));

          formRenderProps.onChange("grnNoOptions", {
            value: result?.length > 0 && result,
          });
        } else {
          formRenderProps.onChange("grnNoOptions", { value: [] });
          formRenderProps.onChange("grn_no", {
            value: null,
          });
        }
      } else {
        formRenderProps.onChange("grnNoOptions", { value: [] });
        formRenderProps.onChange("grn_no", {
          value: null,
        });
      }
    };

    fetchGRNOptions();
  }, [item_id, process_id]);

  return null;
};

const StockReportForm: React.FC = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const itemLoading = useAppSelector((state) => state.item.loading);
  const ItemList = useAppSelector((state) => state.item.ItemList);
  const StockReportList = useAppSelector(
    (state) => state.reports.StockReportList
  );
  const ProcessList = useAppSelector((state) => state.process.ProcessList);

  useEffect(() => {
    dispatch(findAllActiveProcess());

    return () => {
      dispatch(clearStockReportList());
      dispatch(clearItemList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      item_id: values?.item_id ? values?.item_id : null,
      grn_no: values?.grn_no ? values?.grn_no : "",
    };
    dispatch(getItemStockReport(payload));
    // window.open(
    //   `companywisebookingreportview/${payload?.CompanyID}/${payload?.DateType}/${payload?.Type}/${payload?.FromDate}/${payload?.end_Date}`
    // );
  };
  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 10 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[{ width: "40%" }, { width: "60%" }]}
                >
                  <GridLayoutItem colSpan={2}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Stock Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="item_id"
                      label="Product Name"
                      component={FormIncrementalSearch}
                      validator={requiredValidator}
                      astrike={true}
                      placeholder="Type here to search..."
                      fetchIncrementalData={(search: any) =>
                        dispatch(getAllItemIncremental({ search }))
                      }
                      loading={itemLoading}
                      options={ItemList?.map((item: IItem) => {
                        return {
                          value: item?.id,
                          label: item?.product_name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="process_id"
                      label="Department"
                      placeholder="Department"
                      component={FormSelectionField}
                      options={[
                        {
                          value: "0",
                          label: "All",
                        },
                        ...(Array.isArray(ProcessList)
                          ? ProcessList.map((process: IProcess) => ({
                              value: `${process.id}`,
                              label: process.process_name,
                            }))
                          : []),
                      ]}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem></GridLayoutItem>
                  <GridLayoutItem
                    style={{ display: "flex", alignItems: "start", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="grn_no"
                      label="GRN No"
                      component={FormSelectionField}
                      validator={
                        formRenderProps.valueGetter("process_id") &&
                        formRenderProps.valueGetter("process_id") !== "0"
                          ? requiredValidator
                          : skipValidator
                      }
                      options={
                        formRenderProps.valueGetter("grnNoOptions") || []
                      }
                    />
                    <div style={{ marginTop: 35 }}>
                      <ButtonWithLoading
                        label={"View"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                  <ItemChangeWatcher formRenderProps={formRenderProps} />
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
        {loading ? (
          <LoadingPanel gridRef={gridRef} />
        ) : (
          StockReportList &&
          StockReportList.length > 0 && (
            <ShadowCard style={{ marginTop: 10 }}>
              <StockReportGridCommponent />
            </ShadowCard>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 50 };

const StockReportGridCommponent: React.FC = () => {
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);
  const StockReportList = useAppSelector(
    (state) => state.reports.StockReportList
  );

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? StockReportList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const printTable = (data: any[]) => {
    let printbuttons = document.querySelector(
      ".printbuttons"
    ) as HTMLElement | null;
    let report_container = document.querySelector(
      ".report-container"
    ) as HTMLElement | null;

    if (printbuttons) printbuttons.style.display = "none";
    if (report_container) report_container.style.padding = "20px 0";

    let tableRows = data.length;
    let maxRows = tableRows;
    let pageCount = Math.ceil(tableRows / maxRows);
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += `<div class="page-header">Page ${i + 1}</div>`;
      content +=
        '<table class="table table-bordered" style="font-size:10px;margin-bottom:30px;">';
      content += "<tr>";
      content += `<td rowSpan="3" style="width:15%" ><img src="${Logo}" width="100%" /></td>`;
      content +=
        '<td colSpan="2" style="text-align: center;font-size: 14px;font-weight: 600;color: gray;">Harihar Foods Pvt Ltd.</td>';
      content += "</tr>";
      content += "<tr>";
      content += '<td colSpan="2"><h3 style="margin:0;">Stock Report</h3></td>';
      content += "</tr>";
      content += "<tr>";
      content += `<td style="text-align:end;">Page : ${i + 1}</td>`;
      content += "</tr>";

      content += "</table>";

      content += '<table class="table table-bordered" style="font-size:10px;">';
      content += '<thead style="background:lightgray;">';
      content += "<tr>";
      content += '<th style="width:40px;">Sr. No.</th>';
      content += "<th>Item Name</th>";
      content += "<th>GRN No</th>";
      content += "<th>Department Name</th>";
      content += "<th>Warehouse Name</th>";
      content += "<th>Actual Stock</th>";
      content += "</tr>";
      content += "</thead>";
      content += "<tbody>";

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += `<tr style="text-align:center;">`;
        content += `<td>${j + 1}</td>`;
        content += `<td style="text-align:start;">${data[j].product_name}</td>`;
        content += `<td>${data[j].grn_no}</td>`;
        content += `<td style="text-align:start;">${data[j].process_name}</td>`;
        content += `<td style="text-align:start;">${data[j].warehouse_name}</td>`;
        content += `<td style="text-align:end;">${formatIndianNumberForQty(
          +data[j].actual_stock || 0
        )}</td>`;
        content += "</tr>";
      }

      content += "</tbody>";
      content += "</table>";

      // content +=
      //   '<table class="table table-bordered" style="font-size:11px; width:100%; margin:30px 0; text-align:center">';
      // content += "<tr>";
      // content += `<td style="width:33%">Prepared By: <br>Mr. GAURANG MEHTA</br></td>`;
      // content += `<td style="width:34%">Document Status: </br>CONTROL COPY</td>`;
      // content += `<td style="width:33%">Approved By: <br>Mr. PARAG SHAH</br> </td>`;
      // content += "</tr>";
      // content += "</table>";

      content += "</div>";
    }

    let printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error("Failed to open print preview window.");
      return;
    }
    printWindow.document.write(`
      <html>
      <head>
        <title>Print Preview</title>
        <style>
          @page { margin: 1cm; }
          .print-page { page-break-after: always; }
          .table-bordered { width: 100%; border-collapse: collapse; }
          .table-bordered th, .table-bordered td { border: 1px solid black; padding: 8px; }
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${content}
        <script>
          window.onload = function() {
            window.print();
          };
        </script>
      </body>
      </html>
    `);
    printWindow.document.close();

    if (printbuttons) printbuttons.style.display = "block";
    if (report_container) report_container.style.padding = "20px 10px";
  };

  return (
    <>
      {StockReportList && StockReportList.length > 0 ? (
        <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
          <GridLayoutItem className="d-flex justify-content-between align-items-center">
            <Typography.h4 className="m-0">Stock Report List</Typography.h4>
            {isPrintAccess && (
              <Button
                style={{ marginRight: 5 }}
                type="button"
                fillMode={"solid"}
                themeColor={"primary"}
                onClick={() => printTable(StockReportList)}
              >
                Print
              </Button>
            )}
          </GridLayoutItem>
          <GridLayoutItem>
            <Grid
              className="table-bordered"
              style={{ maxHeight: "calc(100vh - 152px)" }}
              filterable={true}
              filter={filter}
              data={
                filter
                  ? filterBy(StockReportList, filter).slice(
                      page.skip,
                      page.take + page.skip
                    )
                  : StockReportList.slice(page.skip, page.take + page.skip)
              }
              onFilterChange={handleFilterChange}
              skip={page.skip}
              take={page.take}
              total={StockReportList.length}
              pageable={{
                buttonCount: 5,
                pageSizes: [10, 25, 50, "All"],
                pageSizeValue: pageSizeValue,
                type: "input",
              }}
              onPageChange={pageChange}
              cells={{
                headerCell: HeaderCustomCell,
                data: MyDataCustomCell,
              }}
            >
              <GridColumn
                field="UniqueNo"
                title="Sr. No."
                width={80}
                filterable={false}
              />
              <GridColumn field="product_name" title="Item Name" />
              <GridColumn field="grn_no" title="GRN No" />
              <GridColumn field="process_name" title="Department Name" />
              <GridColumn field="warehouse_name" title="Warehouse Name" />
              <GridColumn
                field="actual_stock"
                title="Actual Stock"
                cell={(props: any) => (
                  <td className="text-end">
                    {props.dataItem?.actual_stock
                      ? formatIndianNumberForQty(+props.dataItem?.actual_stock)
                      : null}
                  </td>
                )}
              />
            </Grid>
          </GridLayoutItem>
        </GridLayout>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default StockReportForm;
