import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ISalesChallan, ISalesChallanInitialState } from "./salesChallanModel";
import {
  createSalesChallan,
  deleteSalesChallan,
  getAllLimitedSalesChallan,
  getAllSalesChallan,
  getSalesChallanByID,
  getSalesChallanPrint,
  updateSalesChallan,
} from "./services/salesChallan.services";

const initialState: ISalesChallanInitialState = {
  loading: false,
  error: "",
  SalesChallanStateForGST: null,
  SalesChallanDeleteIndex: -1,
  SalesChallanID: null,
  SalesChallanList: [],
  SalesChallanDetail: {
    sales_order_id: "",
    financial_year: null,
    challan_no: null,
    challan_no_string: "",
    challan_date: moment().toDate(),
    vendor_id: null,
    terms_day: null,
    due_date: "",
    sales_ledger_id: null,
    total_amount: null,
    gst: null,
    cgst: null,
    sgst: null,
    igst: null,
    vehicle_no: "",
    transport_name: "",
    is_delivered: false,
    card_scheme: false,
    free_scheme: false,
    // delivery_slip_no: "",
    scheme: "",
    route_id: null,
    cartoon: null,
    bag: null,
    sales_challan_items: [],
  },
  SalesChallanPrintDetail: {},
  FilterSalesChallan: {
    account_name: "",
  },
};

const salesChallanSlice = createSlice({
  name: "salesChallan",
  initialState,
  reducers: {
    clearSalesChallanDetails: (state) => {
      state.SalesChallanDetail = initialState.SalesChallanDetail;
    },
    setSalesChallanID: (state, action) => {
      state.SalesChallanID = action.payload;
    },
    clearSalesChallanID: (state) => {
      state.SalesChallanID = initialState.SalesChallanID;
    },
    setSalesChallanDeleteIndex: (state, action) => {
      state.SalesChallanDeleteIndex = action.payload;
    },
    setSalesChallanStateForGST: (state, action) => {
      state.SalesChallanStateForGST = action.payload;
    },
    clearSalesChallanStateForGST: (state) => {
      state.SalesChallanStateForGST = initialState.SalesChallanStateForGST;
    },
    setFilterSalesChallan: (state, action) => {
      state.FilterSalesChallan = action.payload;
    },
    clearFilterSalesChallan: (state) => {
      state.FilterSalesChallan = initialState.FilterSalesChallan;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSalesChallan.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSalesChallan.fulfilled,
      (state, action: PayloadAction<ISalesChallan[]>) => {
        state.loading = false;
        state.SalesChallanList = action.payload || [];
      }
    );
    builder.addCase(getAllSalesChallan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesChallanList = [];
    });
    builder.addCase(getAllLimitedSalesChallan.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllLimitedSalesChallan.fulfilled,
      (state, action: PayloadAction<ISalesChallan[]>) => {
        state.loading = false;
        state.SalesChallanList = action.payload || [];
      }
    );
    builder.addCase(getAllLimitedSalesChallan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SalesChallanList = [];
    });

    builder.addCase(createSalesChallan.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createSalesChallan.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSalesChallan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateSalesChallan.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSalesChallan.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSalesChallan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(deleteSalesChallan.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSalesChallan.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSalesChallan.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSalesChallanByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSalesChallanByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SalesChallanDetail = action.payload;
    });
    builder.addCase(getSalesChallanByID.rejected, (state, action) => {
      state.loading = false;
      state.SalesChallanDetail = initialState.SalesChallanDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getSalesChallanPrint.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSalesChallanPrint.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SalesChallanPrintDetail = action.payload;
    });
    builder.addCase(getSalesChallanPrint.rejected, (state, action) => {
      state.loading = false;
      state.SalesChallanPrintDetail = initialState.SalesChallanPrintDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearSalesChallanDetails,
  clearSalesChallanID,
  setSalesChallanID,
  setSalesChallanDeleteIndex,
  setSalesChallanStateForGST,
  clearSalesChallanStateForGST,
  setFilterSalesChallan,
  clearFilterSalesChallan,
} = salesChallanSlice.actions;
export default salesChallanSlice.reducer;
