import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllAccountIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import ButtonForAll from "../../components/common/Button";
import { clearItemList } from "../Item/itemSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  createSales,
  generateInvoiceNoForSales,
  getSalesByID,
  updateSales,
} from "./services/sales.services";
import { clearSalesDetails, setSalesStateForGST } from "./salesSlice";
import SalesItemDetailsArray from "./SalesItemDetailsArray";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import {
  COMMONTNCID,
  FINANCIAL_YEAR,
  GUJARATIDFORGST,
} from "../../_contstants/common";
import moment from "moment";
import {
  getAllLimitedSalesChallan,
  getProductionLotNoByItemID,
  getProductionLotNoCurrentStock,
  getSalesChallanByID,
} from "../saleschallan/services/salesChallan.services";
import { clearSalesChallanDetails } from "../saleschallan/salesChallanSlice";
import FormCheckbox from "../../components/formFields/FormCheckbox";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const sales_items = formRenderProps.valueGetter("sales_items");
  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const gst = formRenderProps.valueGetter("gst");
  const frieght = formRenderProps.valueGetter("frieght");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const SalesStateForGST = useAppSelector(
    (state) => state.sales.SalesStateForGST
  );

  useEffect(() => {
    let totalGSTamount = 0;
    sales_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`sales_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (SalesStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
  }, [
    SalesStateForGST,
    sales_items?.map((item: any) => item?.gst_id).join("-"),
    sales_items?.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (sales_items && sales_items.length > 0) {
      sales_items?.map((item: any, index: number) => {
        const quantity = item?.quantity || 0;
        const rate = item?.rate || 0;
        const vatav =
          (+item?.quantity * +item?.rate * item?.vatav_per) / 100 || 0;
        formRenderProps.onChange(`sales_items.${index}.vatav`, {
          value: +vatav,
        });
        formRenderProps.onChange(`sales_items.${index}.amount`, {
          value: +quantity * +rate - vatav,
        });

        // formRenderProps.onChange(`sales_items.${index}.amount`, {
        //   value: +quantity * +rate,
        // });
      });
    }
  }, [
    sales_items.map((item: any) => item?.quantity).join("-"),
    sales_items.map((item: any) => item?.rate).join("-"),
    sales_items.map((item: any) => item?.vatav_per).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (sales_items && sales_items.length > 0) {
      sales_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    sales_items.map((item: any) => item?.quantity).join("-"),
    sales_items.map((item: any) => item?.amount).join("-"),
  ]);

  // useEffect(() => {
  //   if (sales_items && sales_items.length > 0) {
  //     sales_items?.map((item: any) => {
  //       totalamount += +item?.amount || 0;
  //     });
  //   }
  //   formRenderProps.onChange("total_amount", {
  //     value: totalamount,
  //   });
  // }, [sales_items.map((item: any) => item?.amount).join("-")]);

  useEffect(() => {
    formRenderProps.onChange("sub_total", {
      value: +taxable_amount + +gst + +frieght || 0,
    });
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [taxable_amount, gst, frieght, sub_total, round_off]);

  return null;
};

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  // const location = useLocation();
  // const sale_guid = location.state?.sale_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  // const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setSalesStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
          formRenderProps.onChange("terms_day", {
            value: response.payload?.payment_due_days,
          });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
          formRenderProps.onChange("terms_day", {
            value: null,
          });
        }
      };
      // if (!isVendorRef.current) {
      // handleVendorChange();
      // } else {
      // if (!sale_guid) {
      handleVendorChange();
      // }
      // isVendorRef.current = false;
      // }
    } else {
      dispatch(setSalesStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
      formRenderProps.onChange("terms_day", {
        value: "",
      });
    }
  }, [vendor_id]);

  return null;
};

const DateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  const invoice_date = formRenderProps.valueGetter("invoice_date");
  const terms_day = formRenderProps.valueGetter("terms_day");
  // const due_date = formRenderProps.valueGetter("due_date");
  const isDateRef = useRef(true);

  useEffect(() => {
    const changeDueDate = () => {
      if (terms_day) {
        const dueDate = moment(invoice_date, "YYYY-MM-DD").add(
          terms_day || 0,
          "days"
        );

        formRenderProps.onChange("due_date", {
          value: moment(dueDate).toDate() || "",
        });
      }
      if (!terms_day) {
        formRenderProps.onChange("terms_day", {
          value: "",
        });
        formRenderProps.onChange("due_date", {
          value: "",
        });
      }
    };

    if (!isDateRef.current) {
      changeDueDate();
    } else {
      if (!sale_guid) {
        changeDueDate();
      }
      isDateRef.current = false;
    }
  }, [invoice_date, terms_day]);

  return null;
};

const InvoiveNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  const isInvoiceNoRef = useRef(false);

  useEffect(() => {
    const fetchInvoiveNoDate = async () => {
      const response = await dispatch(generateInvoiceNoForSales());
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("invoice_no_string", {
          value: response.payload?.invoice_no_string || "",
        });
      }
    };
    if (!isInvoiceNoRef.current) {
      if (!sale_guid) {
        fetchInvoiveNoDate();
      }
      isInvoiceNoRef.current = true;
    } else {
      isInvoiceNoRef.current = true;
    }
  }, []);

  return null;
};

const InnerItemChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const sales_items = formRenderProps.valueGetter("sales_items");
  const isFetchLotOption = React.useRef(true);
  const sale_guid = location.state?.sale_guid;

  const fetchLotNoOptions = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id) {
        const response = await dispatch(
          getProductionLotNoByItemID(item.item_id)
        );
        const result =
          response.payload
            ?.filter((item: any) => item.item_id === item.item_id)
            .map((lotno: any) => ({
              value: `${lotno.lot_no}`,
              label: `${lotno.lot_no}`,
            })) || [];
        formRenderProps.onChange(`sales_items.${index}.lotNoOptions`, {
          value: result,
        });
      } else {
        formRenderProps.onChange(`sales_items.${index}.lotNoOptions`, {
          value: [],
        });
        formRenderProps.onChange(`sales_items.${index}.lot_no`, {
          value: null,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  const fetchCurrentStock = useCallback(
    async (index: number, item: any) => {
      if (item?.item_id && item?.lot_no) {
        const payload = {
          item_id: +item.item_id,
          lot_no: item.lot_no,
        };
        const response = await dispatch(
          getProductionLotNoCurrentStock(payload)
        );
        formRenderProps.onChange(`sales_items.${index}.current_stock`, {
          value: response.payload?.actual_stock || 0,
        });
      }
    },
    [dispatch, formRenderProps]
  );

  useEffect(() => {
    if (isFetchLotOption.current && !sale_guid) {
      sales_items.forEach((item: any, index: number) => {
        fetchLotNoOptions(index, item);
        fetchCurrentStock(index, item);
      });
      isFetchLotOption.current = false;
    } else if (isFetchLotOption.current && sale_guid) {
      // if (!sale_guid) {
      sales_items.forEach((item: any, index: number) => {
        fetchLotNoOptions(index, item);
        fetchCurrentStock(index, item);
      });
      // }
      isFetchLotOption.current = false;
    }
  }, [
    sale_guid,
    sales_items.map((item: any) => item?.item_id).join("-"),
    sales_items.map((item: any) => item?.lot_id).join("-"),
  ]);

  return null;
};

const CreateSales = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sale_guid = location.state?.sale_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.sales.loading);
  const SalesDetail = useAppSelector((state) => state.sales.SalesDetail);
  // const TermsAndConditionList = useAppSelector(
  //   (state) => state.termsandcondition.TermsAndConditionList
  // );
  const SalesStateForGST = useAppSelector(
    (state) => state.sales.SalesStateForGST
  );
  const SalesChallanList = useAppSelector(
    (state) => state.salesChallan.SalesChallanList
  );
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [SalesDetail]);

  useEffect(() => {
    if (sale_guid) {
      const payload = {
        sale_guid: sale_guid,
      };
      dispatch(getSalesByID(payload));
    }
  }, [sale_guid]);

  useEffect(() => {
    const payload = {
      account_name: "",
      financial_year: FINANCIAL_YEAR,
      limit: 50,
      skip: 0,
    };

    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    // dispatch(getAllActiveTermsAndConditions());
    dispatch(getAllLimitedSalesChallan(payload));

    return () => {
      dispatch(clearSalesChallanDetails());
      dispatch(clearSalesDetails());
      dispatch(clearItemList());
    };
  }, []);

  const handleChallanNoChange = async (
    challan_no: any,
    formRenderProps: FormRenderProps
  ) => {
    const fetchCurrentStock = async (index: number, item: any) => {
      if (item?.item_id && item?.lot_no) {
        const payload = {
          item_id: +item.item_id,
          lot_no: item.lot_no,
        };
        const response = await dispatch(
          getProductionLotNoCurrentStock(payload)
        );
        formRenderProps.onChange(`sales_items.${index}.current_stock`, {
          value: response.payload?.actual_stock || 0,
        });
      }
    };

    if (challan_no) {
      const payload = {
        challan_no: challan_no,
        financial_year: FINANCIAL_YEAR,
      };
      const response = await dispatch(getSalesChallanByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("vendor_id", {
          value: response.payload?.vendor_id,
        });
        formRenderProps.onChange("vendor_options", {
          value: response.payload?.vendor_options,
        });
        formRenderProps.onChange("free_scheme", {
          value: response.payload?.free_scheme,
        });
        formRenderProps.onChange("card_scheme", {
          value: response.payload?.card_scheme,
        });
        // formRenderProps.onChange("delivery_slip_no", {
        //   value: response.payload?.delivery_slip_no,
        // });
        formRenderProps.onChange("transport_name", {
          value: response.payload?.transport_name,
        });
        formRenderProps.onChange("vehicle_no", {
          value: response.payload?.vehicle_no,
        });
        formRenderProps.onChange("bag", {
          value: response.payload?.bag,
        });
        formRenderProps.onChange("cartoon", {
          value: response.payload?.cartoon,
        });
        formRenderProps.onChange("scheme", {
          value: response.payload?.scheme,
        });

        const salesItems =
          response.payload?.sales_challan_items &&
          response.payload?.sales_challan_items.length > 0 &&
          response.payload?.sales_challan_items?.map(
            (item: any, index: number) => {
              fetchCurrentStock(index, item);
              return {
                item_id: item?.item_id,
                item_options: item?.item_options,
                mrp: item?.mrp,
                lot_no: item?.lot_no,
                gst_id: item?.gst_id,
                quantity: item?.quantity,
                free_quantity: item?.free_quantity,
                rate: item?.rate,
                unit_id: item?.unit_id,
                amount: item?.quantity * item?.rate,
                lotNoOptions:
                  item?.lot_no_list?.map((lotno: any) => ({
                    label: lotno?.lot_no,
                    value: lotno?.lot_no,
                  })) || [],
              };
            }
          );
        formRenderProps.onChange("sales_items", {
          value: salesItems || [],
        });
      } else {
        formRenderProps.onChange("vendor_id", {
          value: null,
        });
        formRenderProps.onChange("free_scheme", {
          value: null,
        });
        formRenderProps.onChange("card_scheme", {
          value: null,
        });
        formRenderProps.onChange("vendor_options", {
          value: [],
        });
        formRenderProps.onChange("po_date", {
          value: "",
        });
        // formRenderProps.onChange("delivery_slip_no", {
        //   value: "",
        // });
        formRenderProps.onChange("transport_name", {
          value: "",
        });
        formRenderProps.onChange("vehicle_no", {
          value: "",
        });
        formRenderProps.onChange("bag", {
          value: "",
        });
        formRenderProps.onChange("cartoon", {
          value: "",
        });
        formRenderProps.onChange("scheme", {
          value: "",
        });
      }
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (sale_guid) {
      try {
        const updatePayload: any = {
          id: values?.id ? +values?.id : null,
          sale_guid: sale_guid,
          sales_challan_id: values?.sales_challan_id
            ? +values?.sales_challan_id
            : null,
          financial_year: FINANCIAL_YEAR,
          // invoice_no: values?.invoice_no_string
          //   ? +values?.invoice_no_string
          //   : "",
          cartoon: values?.cartoon ? +values?.cartoon : null,
          scheme: values?.scheme ? values?.scheme : "",
          bag: values?.bag ? +values?.bag : null,
          frieght: values?.frieght ? +values?.frieght : null,
          terms_and_condition_id: +COMMONTNCID,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          // delivery_slip_no: values?.delivery_slip_no
          //   ? values?.delivery_slip_no
          //   : "",
          invoice_date: values?.invoice_date
            ? moment(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          terms_day: values?.terms_day ? +values?.terms_day : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          sales_ledger_id: values?.sales_ledger_id
            ? +values?.sales_ledger_id
            : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          sales_items: values?.sales_items
            ? values?.sales_items?.map((salesitem: any) => {
                return {
                  id: salesitem?.id ? +salesitem?.id : 0,
                  sales_id: values?.id ? +values?.id : null,
                  item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                  mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                  lot_no: salesitem?.lot_no ? salesitem?.lot_no : "",
                  quantity: salesitem?.quantity ? +salesitem?.quantity : 0,
                  free_quantity: salesitem?.free_quantity
                    ? +salesitem?.free_quantity
                    : 0,
                  unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                  rate: salesitem?.rate ? +salesitem?.rate : null,
                  gst_id: salesitem?.gst_id
                    ? parseInt(salesitem?.gst_id.split("--")[0])
                    : null,
                  amount: salesitem?.amount ? +salesitem?.amount : null,
                  vatav_per: salesitem?.vatav_per
                    ? +salesitem?.vatav_per
                    : null,
                  vatav: salesitem?.vatav ? +salesitem?.vatav : null,
                };
              })
            : [],
        };
        if (SalesStateForGST === GUJARATIDFORGST) {
          updatePayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          updatePayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          updatePayload.igst = null;
        } else {
          updatePayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          updatePayload.cgst = null;
          updatePayload.sgst = null;
        }

        const response = await dispatch(updateSales(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/sales");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload: any = {
          sales_challan_id: values?.sales_challan_id
            ? +values?.sales_challan_id
            : null,
          financial_year: FINANCIAL_YEAR,
          // invoice_no: values?.invoice_no_string
          //   ? +values?.invoice_no_string
          //   : "",
          cartoon: values?.cartoon ? +values?.cartoon : null,
          scheme: values?.scheme ? values?.scheme : "",
          bag: values?.bag ? +values?.bag : null,
          frieght: values?.frieght ? +values?.frieght : null,
          terms_and_condition_id: +COMMONTNCID,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          // delivery_slip_no: values?.delivery_slip_no
          //   ? values?.delivery_slip_no
          //   : "",
          invoice_date: values?.invoice_date
            ? moment(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          terms_day: values?.terms_day ? +values?.terms_day : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          sales_ledger_id: values?.sales_ledger_id
            ? +values?.sales_ledger_id
            : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          sales_items: values?.sales_items
            ? values?.sales_items?.map((salesitem: any) => {
                return {
                  id: salesitem?.id ? +salesitem?.id : 0,
                  item_id: salesitem?.item_id ? +salesitem?.item_id : null,
                  mrp: salesitem?.mrp ? +salesitem?.mrp : null,
                  lot_no: salesitem?.lot_no ? salesitem?.lot_no : "",
                  quantity: salesitem?.quantity ? +salesitem?.quantity : 0,
                  free_quantity: salesitem?.free_quantity
                    ? +salesitem?.free_quantity
                    : 0,
                  unit_id: salesitem?.unit_id ? +salesitem?.unit_id : null,
                  rate: salesitem?.rate ? +salesitem?.rate : null,
                  gst_id: salesitem?.gst_id
                    ? parseInt(salesitem?.gst_id.split("--")[0])
                    : null,
                  amount: salesitem?.amount ? +salesitem?.amount : null,
                  vatav_per: salesitem?.vatav_per
                    ? +salesitem?.vatav_per
                    : null,
                  vatav: salesitem?.vatav ? +salesitem?.vatav : null,
                };
              })
            : [],
        };
        if (SalesStateForGST === GUJARATIDFORGST) {
          insertPayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          insertPayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          insertPayload.igst = null;
        } else {
          insertPayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }
        const response = await dispatch(createSales(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/sales");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={SalesDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {sale_guid ? "Update Sales Invoice" : "Add Sales Invoice"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="invoice_no_string"
                    label="Invoice No"
                    disabled={true}
                    placeholder="Invoice No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}></GridLayoutItem>
                <GridLayoutItem>
                  {(formRenderProps.valueGetter("free_scheme") ||
                    formRenderProps.valueGetter("card_scheme")) && (
                    <div
                      style={{
                        marginTop: 30,
                        background: "whitesmoke",
                        padding: "7px 10px",
                        borderRadius: 5,
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      {formRenderProps.valueGetter("free_scheme") && (
                        <Field
                          name="free_scheme"
                          wrapperStyle={{ marginTop: 0 }}
                          label="Free Scheme"
                          disabled={true}
                          component={FormCheckbox}
                          labelClassName={"fw-bold ms-2"}
                        />
                      )}
                      {formRenderProps.valueGetter("card_scheme") && (
                        <Field
                          name="card_scheme"
                          wrapperStyle={{ marginTop: 0 }}
                          label="Card Scheme"
                          disabled={true}
                          component={FormCheckbox}
                          labelClassName={"fw-bold ms-2"}
                        />
                      )}
                    </div>
                  )}
                </GridLayoutItem>
                {sale_guid ? (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="sales_challan_id_label"
                      label="Sales Challan No"
                      disabled={true}
                      placeholder="Sales Challan No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem
                    colSpan={2}
                    style={{ display: "flex", gap: 10 }}
                  >
                    <Field
                      wrapperClassName="w-100"
                      name="sales_challan_id"
                      label="Sales Challan No"
                      placeholder="Sales Challan No"
                      disabled={sale_guid ? true : false}
                      component={FormSelectionField}
                      options={SalesChallanList?.map((saleschallan: any) => {
                        return {
                          value: saleschallan?.challan_no,
                          label: `${saleschallan?.challan_no} - ${saleschallan?.challan_date} - ${saleschallan?.account_name}`,
                        };
                      })}
                    />
                    <ButtonForAll
                      label={"View"}
                      disabled={
                        sale_guid ||
                        !formRenderProps.valueGetter("sales_challan_id")
                          ? true
                          : false
                      }
                      style={{ marginTop: 35 }}
                      onClick={() =>
                        handleChallanNoChange(
                          formRenderProps.valueGetter("sales_challan_id"),
                          formRenderProps
                        )
                      }
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Customer Name"
                    placeholder="Customer Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="invoice_date"
                    label="Invoice Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="sales_ledger_id"
                    label="Sales Ledger"
                    placeholder="Sales Ledger"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={
                      []
                      //     CONDITION?.map((condition: any) => {
                      //   return {
                      //     value: condition?.value,
                      //     label: condition?.label,
                      //   };
                      // })
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="scheme"
                    label="Scheme"
                    placeholder="Scheme"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="terms_day"
                    label="Terms Day"
                    placeholder="0"
                    disabled={true}
                    maxNumber={999}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="due_date"
                    label="Due Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="delivery_slip_no"
                    label="Delivery Slip No"
                    placeholder="0"
                    component={FormTextField}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="transport_name"
                    label="Transport Name"
                    placeholder="i.e. John Smith"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vehicle_no"
                    label="Vehicle No"
                    placeholder="i.e. GJ01AA1111"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="cartoon"
                    label="Cartoon"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="bag"
                    label="Bag"
                    placeholder="0"
                    component={FormNumericTextField}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="terms_and_condition_id"
                    label="Terms And Conditions"
                    placeholder="Terms And Conditions"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={TermsAndConditionList?.map(
                      (tnc: ITermsAndCondition) => {
                        return {
                          value: tnc?.id,
                          label: tnc?.terms_and_condition,
                        };
                      }
                    )}
                  />
                </GridLayoutItem> */}
                <QtyChangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
                <DateChangeWatcher formRenderProps={formRenderProps} />
                <InvoiveNoChangeWatcher formRenderProps={formRenderProps} />
                <InnerItemChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  // style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={SalesItemDetailsArray}
                    name="sales_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={1}></GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "20%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                      {SalesStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "20%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {SalesStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "20%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {SalesStateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "60%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "10%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "20%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Freight Charges
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "20%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="frieght"
                            placeholder="0"
                            // disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "20%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "60%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "20%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "0%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "10%", textAlign: "center" }}>:</td>
                        <td style={{ width: "20%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={sale_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/sales")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateSales;
